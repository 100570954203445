ul#words {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    clear: both;
    // align-items: flex-end;
}

ul#words li {
    margin-right: 1em;
}

ul#words li a {
    letter-spacing: -1px;
}

.words {
    li {
        a, a:hover {
            color: var(--font-color);
            text-decoration: none;
            cursor: default;
        }
    }

    &--clickable {
        li {
            a:not(.word--selected), a:not(.word--selected):hover {
                color: var(--link-color);
                cursor: pointer;
            }

            a:hover:not(.word--selected) {
                text-decoration: underline;
            }
        }
    }

    .word {
        border: solid 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 15px;
        display: inline-block;
        padding: 8px 15px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

        &--selected, &--selected:hover {
            color: var(--background-color);
            background-color: var(--link-color);
            box-shadow: none;
        }
    }
}
