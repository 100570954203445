.homepage__section-stone {
    margin-bottom: 2em;
    display: flex;
    justify-content: flex-end;

    a {
        background-image: url(/dist/img/stein.jpg);
        background-position: 50% 0%;
        display: inline-block;
        padding: 100px 15px 15px 15px;
        border-radius: 10px 20px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 1.5rem;

        span {
            background: rgba(0, 0, 0, 0.2);
            padding: 8px;
            border-radius: 3px;
        }
    }
}
