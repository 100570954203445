.gallery {
    margin-top: 2em;
    margin-bottom: 2em;

    .grid-sizer,
    .grid-item {
        width: 25%;
    }

    .grid-item {
        img {
           max-width: 100%;
           padding: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        .grid-sizer,
        .grid-item {
            width: 50%;
        }
    }

    @media screen and (max-width: 768px) {
        .grid-sizer,
        .grid-item {
            width: 50%;
        }
    }

    @media screen and (max-width: 576px) {
        .grid-sizer,
        .grid-item {
            width: 100%;
        }
    }
}