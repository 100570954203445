.redactor-image--position-left,
.redactor-image--position-right,
.redactor-image--position-center {
    max-width: 50%;
}

.redactor-image--position-left {
    float: left;
    margin: 0 1em 1em 0;
}

.redactor-image--position-right {
    float: right;
    margin: 0 0 1em 1em;
}

.redactor-image--position-center {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 576px) {
    .redactor-image--position-left,
    .redactor-image--position-right,
    .redactor-image--position-center {
        width: 100%;
    }

    .redactor-image--position-left,
    .redactor-image--position-right {
        float: none;
        margin: 1em 0;
    }
}
