.breadcrumb {
    background-color: transparent;
    padding-left: 0;
    padding-bottom: 0;

    .iconic * {
        fill: var(--link-color);
        stroke: var(--link-color);
    }
}
