/* Iconic theme rules. Don't delete! */
/* Hide all icons that are waiting to be injected */
img.iconic {
    display: none;
}

/* Make sure filled and text elements only get fills */
.iconic-property-fill, .iconic-property-text {
    stroke: none !important;
}

/* Make sure stroked elements only get strokes */
.iconic-property-stroke {
    fill: none !important;
}

.iconic {
    position: relative;
    top: -2px;
}

.iconic * {
    fill: var(--nav-icon-color);
    stroke: var(--nav-icon-color);
}
