
html, body {
    background: var(--background-color);
}

html, body, div, div *, p, ul, li, a {
    font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
    font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Whitney SSm A", "Whitney SSm B", sans-serif;
    font-weight: 500;
}

/* h1 is set to 2.5rem by bootstrap */

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.3rem;
}

figcaption {
    font-style: italic;
    font-size: 0.9rem;
}

.main ul {
    overflow: hidden;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page__header {
    margin-bottom: 1em;
}

.page__footer {
    margin-top: 2em;
}

.page__header, .page__footer {
    flex: 0 0 auto;
}

.page__main {
    flex: 1 1 auto;
}

.page__header .navbar {
    padding-left: 0;
}

.footer {
    text-align: center;
    padding-bottom: 1em;

    &__links {
        margin-top: 1em;

        a {
            display: inline-block;
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }

        a:first-child {
            margin-left: 0.5em;
        }

        a:not(:last-child) {
            margin-right: 0.5em;
        }

        img {
            height: 35px;
            width: auto;
        }
    }
}
