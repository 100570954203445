.text figure img {
    max-width: 100%;
}

.text blockquote {
    font-family: "Chronicle Hairline A", "Chronicle Hairline B";
    font-size: 1.1rem;
    font-style: italic;
    font-weight: normal;
    border-left: solid 4px lightgrey;
    padding-left: 0.5em;
}

.text strong {
    font-weight: 500;
}
