.navbar-top {
    .navbar {
        padding-bottom: 0;
        margin-bottom: -1em;
    }
}

.navbar {
    .navbar-brand {
        font-size: 1.4rem;
        margin-bottom: 0.3em;

        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }
    }
}